import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { service } from "../../services";
import { useAuth } from "@clerk/clerk-react";
import EastIcon from "@mui/icons-material/East";
import Modal from "../Modal";
import CreateUserMenu from "./CreateUserMenu";
import LoadingSpinner from "./Dashboard/LoadingSpinner";

const UserForm: React.FC = () => {
  const { getToken } = useAuth();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInfoModalOpen(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    setIsSubmitting(true);
    try {
      const token = await getToken();

      // Send data to your backend or service
      const redirectURL: string = await service.adminService.createUser(
        token,
        values
      );
      window.location.replace(redirectURL);

      // Send data to Make.com webhook
      await fetch(
        "https://hook.us1.make.com/a73m91s79epf1sppjsb9eoi2ug6zle90",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values), // Send the form values as the body
        }
      );
    } catch (error) {
      console.error("Failed to submit form", error);
      setErrorModalOpen(true);
    } finally {
      setIsSubmitting(false);
      actions.setSubmitting(false);
    }
  };

  const inputClass =
    "my-4 block w-full py-2 px-4 border border-brand-logo rounded-full bg-transparent text-brand-green-dark placeholder-green-700 focus:border-brand-green-dark outline-none";
  const inputClass2 =
    "my-4 block w-full px-4 py-3 border border-brand-logo rounded-[35px] bg-transparent text-brand-green-dark placeholder-green-700 overflow-hidden focus:border-brand-green-dark outline-none h-[150px]";
  const labelClass = "block text-brand-logo font-bold mb-2";

  return (
    <div className="bg-brand-green flex justify-center items-center min-h-screen w-full px-4 ">
      <CreateUserMenu />
      <div className="hidden lg:flex w-1/2 bg-brand-green justify-center items-center">
        <div className="w-3/5 h-full ">
          <img
            src="/images/signup.png"
            alt="Signup"
            className="object-cover w-full h-full -ml-4"
          />
        </div>
        <div className="w-2/5 h-full flex items-center ml-4">
          <h1 className="leading-none text-brand-logo text-6xl">
            Let's<br></br>make<br></br>biz a<br></br>breeze.
          </h1>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-8 w-full lg:w-1/2 h-full pt-32">
        <div className="mb-8 ml-8 flex w-full justify-center gap-12 font-Black lg:gap-16">
          <div className=" text-brand-green-dark bg-brand-logo flex  h-8 w-8 items-center justify-center rounded-full">
            1
          </div>
          <div className="bg-brand-cream opacity-30 text-brand-green-dark flex  h-8 w-8 items-center justify-center rounded-full">
            2
          </div>
          <div className=" text-brand-green-dark bg-brand-cream opacity-30 flex  h-8 w-8 items-center justify-center rounded-full">
            3
          </div>
          <div className=" text-brand-green-dark bg-brand-cream opacity-30 flex  h-8 w-8 items-center justify-center rounded-full">
            4
          </div>
        </div>
        <h2 className="text-xl lg:text-4xl font-bold mb-4 text-brand-green-dark w-full">
          Create Your Profile
        </h2>
        <Formik
          initialValues={{
            name: "",
            email: "",
            businessProfile: {
              businessName: "",
              businessType: "",
              businessSize: "",
              businessURL: "",
              businessDescription: "",
            },
          }}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form className="space-y-4 w-full">
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col w-full md:w-1/2 md:pr-8">
                  <div>
                    <label htmlFor="name" className={labelClass}></label>
                    <Field
                      id="name"
                      name="name"
                      placeholder="First name*"
                      className={inputClass}
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className={labelClass}></label>
                    <Field
                      id="email"
                      name="email"
                      placeholder="Work email*"
                      type="email"
                      className={inputClass}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="businessProfile.businessName"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessName"
                      name="businessProfile.businessName"
                      placeholder="Business name*"
                      className={inputClass}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:w-1/2">
                  <div>
                    <label
                      htmlFor="businessProfile.businessType"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessType"
                      name="businessProfile.businessType"
                      placeholder="Profession*"
                      className={inputClass}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="businessProfile.businessSize"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessSize"
                      name="businessProfile.businessSize"
                      placeholder="Number of employees*"
                      type="number"
                      className={inputClass}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="businessProfile.businessURL"
                      className={labelClass}
                    ></label>
                    <Field
                      id="businessProfile.businessURL"
                      name="businessProfile.businessURL"
                      placeholder="URL*"
                      className={inputClass}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="businessProfile.businessDescription"
                  className={labelClass}
                ></label>
                <Field
                  as="textarea"
                  id="businessProfile.businessDescription"
                  name="businessProfile.businessDescription"
                  placeholder="List at least one area of your business you're wanting Sloane's help*"
                  className={inputClass2}
                  multiline
                />
                <div className="relative">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`flex items-center text-brand-green-dark py-2 px-4 rounded-md justify-end w-full hover:text-brand-logo hover:underline ${
                      isSubmitting ? "opacity-50" : ""
                    } transition-colors duration-300 ease-in-out`}
                  >
                    {isSubmitting ? "Submitting" : "Continue"}
                    <span></span>
                    <EastIcon className="ml-2 hover:text-brand-logo text-brand-green-dark " />
                  </button>
                  {isSubmitting && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <Modal isOpen={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
          <h2 className="text-xl font-Archivo mb-4 text-brand-green">
            Whoops Error
          </h2>
          <p className="text-brand-green-dark mb-4">
            There was an error submitting your form. <br></br><br></br> Please send us an email with your name and business name to <span className="font-bold">hello@sloane.biz</span> so we can finalise your submission.
          </p>
        </Modal>

        <Modal isOpen={infoModalOpen} onClose={() => setInfoModalOpen(false)}>
          <h2 className="text-2xl font-Black text-brand-green">
            This is Exciting!
          </h2>
          <p className="text-brand-green-dark mb-4">
            After filling out this form,
            there are <span className="font-bold">2 more steps</span> to complete your sign-up:
          </p>
          <ul className="list-disc pl-6 my-2 text-brand-green-dark">
            <li>Create your Stripe subscription</li>
            <li>Book your time for our 1:1 Deep Dive interview</li>
          </ul>
          <p className="text-brand-green-dark mt-4">
            Last but not least, you'll receive an email with some things to
            prepare.
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default UserForm;
