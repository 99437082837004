import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import { ChatProvider } from "./context/ChatContext";
import { ThemeProvider } from "./components/Pages/chat/ThemeContext";
import { Toaster } from "react-hot-toast";
import { PromptProvider } from "./context/PromptContext";
import AppContent from "./AppContent";
import { AssistantProvider } from "./context/assistantContext";
import { FolderProvider } from "./context/FolderContext";

const App: React.FC = () => {
	return (
		<ThemeProvider>
			<ClerkProvider
				publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}
			>
				<AssistantProvider>
					<FolderProvider>
						<ChatProvider>
							<Toaster position="top-right" />
							<Router>
								<PromptProvider>
									<AppContent />
								</PromptProvider>
							</Router>
						</ChatProvider>
					</FolderProvider>
				</AssistantProvider>
			</ClerkProvider>
		</ThemeProvider>
	);
};

export default App;
