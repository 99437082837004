
import { useSubscriptionStatus } from "../../../hooks/useSubscriptionStatus";
import PleaseSubscribe from "./PleaseSubscribe";
import DashboardContent from "./DashboardContent";
import { useAuth } from "@clerk/clerk-react"; // Make sure this import is correct
import { useChat } from "../../../context/ChatContext";

function Dashboard() {
	const { isSubscribed, isLoading } = useSubscriptionStatus();
	const { getToken } = useAuth(); // Correctly call useAuth here to get getToken
	const { setSelectedChat } = useChat();

	const handleClickSubscribe = async () => {
		const token = await getToken(); // Await getToken call here, outside the fetch
		const response = await fetch(
			`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-checkout`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`, // Use token here
				},
			},
		);
		const data = await response.json();
		window.location.replace(data.url);
	};

	if (isLoading) {
		return (
			<div className="w-screen h-screen bg-brand-green flex justify-center items-center text-brand-orange"></div>
		);
	}

	setSelectedChat(undefined);
	// Add the PleaseSubscribe component and the DashboardContent component here.

	return (
		<div className="w-screen-1 min-h-screen  bg-brand-green flex justify-center items-center">
			<div className="max-w-[1440px] w-full flex flex-col justify-between h-full ">
				{!isSubscribed ? (
					<PleaseSubscribe onClickSubscribe={handleClickSubscribe} />
				) : (
					<DashboardContent />
				)}
			</div>
		</div>
	);
}

export default Dashboard;
